import {
  useQuery,
  UseQueryOptions,
  QueryKey,
  useQueryClient,
} from '@tanstack/vue-query'
import { until } from '@vueuse/core'
import {
  isReactive,
  reactive,
  toRefs,
  Ref,
  MaybeRef,
  toValue,
  computed,
} from 'vue'
import { z } from 'zod'
import {
  createUrl,
  Endpoint,
  useUrl as useDeprecatedUrl,
} from '@/api/utils/url'
import { appsignal } from '@/modules/base/composable/useAppsignal'
import { useCurrentCompany } from '@/modules/base/composable/useCurrentCompany'
import { QueryParam, useUrl } from '../composable/useUrl'
import { FileUploadConstraints } from '../useFileUploadConstraints.types'
import { changeCamelCaseToSnake, changeSnakeToCamelCase } from './casing'

// * ⬇️ Query ⬇
export type FetchOptions = RequestInit & {
  method: 'GET' | 'DELETE' | 'PATCH' | 'POST'
}

/**
 * Universal fetch with credentials, JSON headers and error handling
 */
export const universalTypedFetch = async <Data>(
  url: string,
  schema: z.ZodType<Data, z.ZodTypeDef, unknown>,
  options?: FetchOptions
): Promise<Data> => {
  try {
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      ...options,
    })

    if (!response.ok) {
      const responseText = await response.text()
      throw new Error(responseText)
    }

    const responseText = await response.text()
    const responseJSON = responseText.length
      ? JSON.parse(changeSnakeToCamelCase(responseText))
      : undefined

    return schema.parse(responseJSON)
  } catch (error) {
    await appsignal.send(error as Error, (span) => {
      span.setParams({
        url,
        options: JSON.stringify(options),
      })
    })
    // * Throw error for @tanstack/vue-query
    throw error
  }
}

export const stringifyBody = (body: unknown) =>
  changeCamelCaseToSnake(JSON.stringify(body))

export const composeCompanyQuery =
  <ResponseData>(
    schema: z.ZodType<ResponseData, z.ZodTypeDef, unknown>,
    key: readonly string[],
    queryParams: MaybeRef<QueryParam> = {}
  ) =>
  <ResponseDataTransformed = ResponseData>(
    options?: Partial<
      UseQueryOptions<ResponseData, unknown, ResponseDataTransformed>
    >
  ) => {
    const { createUrl, withCompanyId } = useUrl()

    return useQuery({
      queryKey: withCompanyId([...key, queryParams]),
      queryFn: ({ queryKey }) => {
        const _queryKey = [...queryKey]
        const _queryParams = _queryKey.pop() as QueryParam

        return universalTypedFetch<ResponseData>(
          createUrl(_queryKey, _queryParams),
          schema
        )
      },
      ...options,
    })
  }

export const composeQuery =
  <ResponseData>(
    schema: z.ZodType<ResponseData, z.ZodTypeDef, unknown>,
    key: QueryKey,
    queryParams: MaybeRef<QueryParam> = {}
  ) =>
  <ResponseDataTransformed = ResponseData>(
    options?: Partial<
      UseQueryOptions<ResponseData, unknown, ResponseDataTransformed>
    >
  ) => {
    const { createUrl } = useUrl()

    return useQuery({
      queryKey: computed<readonly unknown[]>(() => [
        ...key,
        toValue(queryParams),
      ]),
      queryFn: ({ queryKey }) => {
        const _queryKey = [...queryKey]
        const _queryParams = _queryKey.pop() as QueryParam

        return universalTypedFetch<ResponseData>(
          createUrl(_queryKey, _queryParams),
          schema
        )
      },
      ...options,
    })
  }

/**
 * Compose query with mocked API
 * @description It should always mimic the shape of `composeQuery`
 * @param key query endpoint key
 * @param tempData locally imported json response mock
 */
export const composeQueryTemp =
  <ResponseData>(key: QueryKey, tempData: Record<string, unknown>) =>
  <ResponseDataTransformed = ResponseData>(
    options?: UseQueryOptions<ResponseData, unknown, ResponseDataTransformed>
  ) => {
    return useQuery({
      queryKey: key,
      queryFn: () =>
        JSON.parse(changeSnakeToCamelCase(JSON.stringify(tempData))),
      ...options,
    })
  }

// * ⬇️ Mutation ⬇
export const createMutationFn = <Variables>(options: {
  method: 'PATCH' | 'POST' | 'DELETE'
  path: (variables: Variables) => string
  body?:
    | ((variables: Variables) => Record<string, unknown>)
    | Record<string, unknown>
  returnedSchema?: z.ZodType
}) => {
  return (variables: Variables) => {
    return universalTypedFetch(
      options.path(variables),
      options.returnedSchema ?? z.undefined(),
      {
        method: options.method,
        body: options.body
          ? stringifyBody(
              typeof options.body === 'function'
                ? options.body(variables)
                : options.body
            )
          : undefined,
      }
    )
  }
}

export const createFileUploadMutationFn = <Variables>(options: {
  method: 'POST' | 'PATCH'
  path: (variables: Variables) => string
  constraints: Ref<FileUploadConstraints | undefined>
  returnedSchema?: z.ZodType
}) => {
  return async (variables: Variables & { file: File }) => {
    const { maxSize } = await until(options.constraints).not.toBeUndefined()
    if (variables.file.size > maxSize) {
      throw new Error('File size is too big')
    }

    const form = new FormData()
    form.append('file', variables.file)

    return universalTypedFetch(
      options.path(variables),
      options.returnedSchema ?? z.undefined(),
      {
        method: options.method,
        body: form,
        headers: undefined,
      }
    )
  }
}

export const useComposeInvalidation =
  <Params extends unknown[]>(
    composer: (context: {
      params: Params
      withCompanyId: (url: readonly unknown[]) => readonly unknown[]
    }) => QueryKey[]
  ) =>
  (...params: Params) => {
    const queryClient = useQueryClient()
    const { withCompanyId } = useDeprecatedUrl()

    return {
      invalidate: () =>
        Promise.all(
          composer({ params, withCompanyId }).map((url) => {
            return queryClient.invalidateQueries({
              queryKey: url,
            })
          })
        ),
    }
  }

// ! Deprecated code below !

/**
 * Compose a query
 * @deprecated use {@link composeQuery} instead
 * @param key a URL string or array of URL parts will use {@link createUrl} to transform it
 */
export const composeTypedQuery =
  <ResponseData>(
    key: readonly unknown[],
    schema: z.ZodType<ResponseData, z.ZodTypeDef, unknown>
  ) =>
  <ResponseDataTransformed = ResponseData>(
    options?: Partial<
      UseQueryOptions<ResponseData, unknown, ResponseDataTransformed>
    >
  ) => {
    return useQuery<ResponseData, unknown, ResponseDataTransformed>({
      queryKey: key,
      queryFn: async ({ queryKey }) => {
        const url = createUrl(...queryKey)
        const result = await universalTypedFetch<ResponseData>(url, schema)
        if (!result) {
          throw new Error('Response text not available.')
        }
        return result
      },
      ...options,
    })
  }

/**
 * @deprecated use {@link composeCompanyQuery} instead
 */
export const composeTypedQueryWithCompanyId =
  <ResponseData>(
    key: readonly unknown[],
    schema: z.ZodType<ResponseData, z.ZodTypeDef, unknown>
  ) =>
  <ResponseDataTransformed = ResponseData>(
    options?: Partial<
      UseQueryOptions<ResponseData, unknown, ResponseDataTransformed>
    >
  ) => {
    const { createUrl, withCompanyId } = useDeprecatedUrl()

    return useQuery({
      queryKey: withCompanyId(key),
      queryFn: ({ queryKey }) => {
        const url = createUrl(...queryKey)
        return universalTypedFetch<ResponseData>(url, schema)
      },
      ...options,
    })
  }

/**
 * Compose a query
 * @deprecated use {@link composeTypedQuery} instead
 * @param key a URL string or array of URL parts will use {@link createUrl} to transform it
 */
const composeQueryDeprecated =
  <ResponseData>(key: QueryKey) =>
  <ResponseDataTransformed = ResponseData>(
    options?: Partial<
      UseQueryOptions<ResponseData, unknown, ResponseDataTransformed>
    >
  ) => {
    return useQuery({
      queryKey: key,
      queryFn: async ({ queryKey }) => {
        const url = createUrl(...queryKey)
        const result = await universalFetch<ResponseData>(url)
        if (!result) {
          throw new Error('Response text not available.')
        }
        return result
      },
      ...options,
    })
  }

/**
 * Compose query with companyId prefix
 * @deprecated use {@link composeCompanyQuery} instead
 * @description It should implement {@link composeQueryDeprecated} data flow
 * @example of reactive query key
 * ```ts
 *   const id = ref(0)
 *   const query = composeUseQueryWithCompanyId([id])
 * ```
 * @param key query key, will be added after companyId prefix. Refs will be unwrapped internally with `reactive`
 * @returns composed query
 */
export const composeUseQueryWithCompanyId =
  <DataRaw>(key: unknown[]) =>
  <DataTransformed = DataRaw>(
    options?: Partial<UseQueryOptions<DataRaw, unknown, DataTransformed>>
  ) => {
    const { currentCompanyId } = useCurrentCompany()

    const query = composeQueryDeprecated<DataRaw>([
      Endpoint.Companies,
      currentCompanyId,
      ...key,
    ])

    const defaultOptions = {
      staleTime: 10000,
    }

    const optionsWithDefault =
      options && isReactive(options)
        ? reactive({
            ...defaultOptions,
            ...toRefs(options),
          })
        : {
            ...defaultOptions,
            ...(options ?? {}),
          }
    return query<DataTransformed>(optionsWithDefault)
  }

/**
 * Universal fetch with credentials, JSON headers and error handling
 * @deprecated use {@link universalTypedFetch} instead
 */
export const universalFetch = async <DataRaw>(
  url: string,
  options?: FetchOptions
): Promise<DataRaw | undefined> => {
  try {
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      ...options,
    })

    if (!response.ok) {
      const responseText = await response.text()
      throw new Error(responseText)
    }

    const responseText = await response.text()
    if (responseText.length) {
      return JSON.parse(changeSnakeToCamelCase(responseText))
    } else {
      return undefined
    }
  } catch (error) {
    await appsignal.send(error as Error)
    // * Throw error for @tanstack/vue-query
    throw error
  }
}
